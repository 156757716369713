<template>
  <div>
    <template v-if="isLoaded">
      <Editor :id="uuid" ref="editor" :api-key="key" :init="init" v-on="$listeners" :value="value" v-bind="$attrs"
        :disabled="false" tag-name="div" />
      <AppSignature ref="AppSignature" @input="setSignature($event)" />
    </template>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import AppSignature from "@/components/app/forms/AppSignature";

// Plugins
import plugins from "@/enums/AppEditor/plugins";
import menu from "@/enums/AppEditor/menu";
import toolbar from "@/enums/AppEditor/toolbar";
import help_tabs from "@/enums/AppEditor/help_tabs";
import specialChars from "@/enums/AppEditor/specialChars";
import API2PDF from "api2pdf";

export default {
  props: {
    autocomplete: {
      type: Boolean,
      default: false,
    },
    value: {},
  },

  components: {
    Editor,
    AppSignature,
  },

  data() {
    return {
      isLoaded: false,
      formHasChanges: false,
      editoInstance: null,
      key: process.env.VUE_APP_EDITOR_KEY,
      plugins,
      menu,
      toolbar,
      help_tabs,
      specialChars,
      uuid: "editor-" + Math.random().toString(36).substr(2, 9),
    };
  },

  watch: {
    value: {
      handler(form) {
        this.formHasChanges = true;
      },
      deep: true,
    },
  },

  created() {
    this.open();
  },

  beforeDestroy() {
    this.close();
  },

  methods: {
    open() {
      this.createdEditor().then((config) => {
        this.isLoaded = true;
        this.init = config;
      });
    },

    createdEditor() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          resolve({
            image_advtab: true,
            images_upload_handler: this.uploadImage,
            mobile: { menubar: true },
            object_resizing: false,
            language: "pt_BR",
            selector: "#editor",
            height: 500,
            plugins: this.plugins,
            menu: this.menu,
            toolbar: this.toolbar,
            help_tabs: this.help_tabs,
            setup: this.setupEditor(),

            // image_title: false,
            // automatic_uploads: false,
            // file_picker_types: 'image',
            image_advtab: false,
            // image_caption: false
            image_title: false,
            /* enable automatic uploads of images represented by blob or data URIs*/
            automatic_uploads: false,
            /*
              URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
              images_upload_url: 'postAcceptor.php',
              here we add custom filepicker only to Image dialog
            */
            file_picker_types: 'image',
            /* and here's our custom image picker*/
            file_picker_callback: (cb, value, meta) => {
              const input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');

              input.addEventListener('change', (e) => {
                const file = e.target.files[0];

                const reader = new FileReader();
                reader.addEventListener('load', () => {
                  /*
                    Note: Now we need to register the blob in TinyMCEs image blob
                    registry. In the next release this part hopefully won't be
                    necessary, as we are looking to handle it internally.
                  */
                  const id = 'blobid' + (new Date()).getTime();
                  const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                  const base64 = reader.result.split(',')[1];
                  const blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);

                  /* call the callback and populate the Title field with the file name */
                  cb(blobInfo.blobUri(), { title: file.name });
                });
                reader.readAsDataURL(file);
              });

              input.click();
            },

          });
        });
      });
    },

    setupEditor() {
      return (editor) => {
        if (this.autocomplete) {
          this.getAutoCompleteChars(editor);
        }
        this.addSignature(editor);
      };
    },

    getAutoCompleteChars(editor) {
      const onAction = (autocompleteApi, rng, value) => {
        editor.selection.setRng(rng);
        editor.insertContent(value);
        autocompleteApi.hide();
      };
      const getMatchedChars = (pattern) => {
        return this.specialChars.filter(
          (char) => char.text.indexOf(pattern) !== -1
        );
      };

      return editor.ui.registry.addAutocompleter("specialchars_cardmenuitems", {
        ch: "{",
        minChars: 1,
        columns: 1,
        highlightOn: ["char_name"],
        onAction: onAction,
        fetch: (pattern) => {
          return new Promise((resolve) => {
            const results = getMatchedChars(pattern).map((char) => ({
              type: "cardmenuitem",
              value: char.value,
              label: char.text,
              items: [
                {
                  type: "cardcontainer",
                  direction: "vertical",
                  items: [
                    {
                      type: "cardtext",
                      text: char.text,
                      name: "char_name",
                    },
                    { type: "cardtext", text: char.value },
                  ],
                },
              ],
            }));
            resolve(results);
          });
        },
      });
    },

    uploadImage(blobInfo, success, failure) {
      let file = blobInfo.blob();
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        let base64 = e.target.result;
        success(base64);
        const input = document.querySelector(
          ".tox-dialog__body input[type=file]"
        );
        if (input) input.value = "";
      };
      reader.onerror = (err) => {
        failure(err.message);
      };
    },

    addSignature(editor) {
      editor.ui.registry.addButton("addSignature", {
        text: "Assinatura",
        onAction: () => this.$refs.AppSignature.open(),
      });
    },

    setSignature(signature) {
      this.$refs.editor.editor.setContent(
        this.$refs.editor.editor.getContent() + signature
      );
    },
    print() {
      this.$refs.editor.editor.execCommand("mcePrint");
    },

    close() {
      this.isLoaded = false;
      this.init = null;
    },

    generatePDF() {
      return new Promise((resolve, reject) => {
        const a2pClient = new API2PDF(process.env.VUE_APP_API_PDF_kEY);
        const content = this.$refs.editor.editor.getContent();
        a2pClient
          .wkHtmlToPdf(content)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    share(file_name) {
      file_name = file_name || "documento.pdf";
      this.generatePDF().then((pdf) => {
        if (pdf.Success) {
          if (navigator.share) {
            fetch(pdf.FileUrl)
              .then((response) => response.blob())
              .then((blob) => {
                const file = new File([blob], file_name, {
                  type: "application/pdf",
                });
                const filesArray = [file];
                navigator.share({
                  files: filesArray,
                  title: "Compartilhar Documento",
                  text: "Compartilhar Documento",
                });
              });
          }
        }
      });
    },

    download(file_name) {
      file_name = file_name || "documento.pdf";
      this.generatePDF().then((pdf) => {
        if (pdf.Success) {
          fetch(pdf.FileUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], file_name, {
                type: "application/pdf",
              });
              const a = document.createElement("a");
              a.href = URL.createObjectURL(file);
              a.download = file_name;
              a.click();
            });
        }
      });
    },
  },
};
</script>

<style lang="sass">
.tox .tox-statusbar a,
.tox .tox-statusbar__path-item
  display: none

button.tox-button
  color: #fff
</style>
